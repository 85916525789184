<template>
    <div class="Projectcation Projectcations" style="margin-top:20px;">
        <div class="Project_button">
            <span>选择类型</span>
            <el-select v-model="p_type" placeholder="请选择类型" size="small" clearable @change="detypes(p_type)" @clear="clears" style="margin-right:10px">
                <el-option label="创新训练项目" value="1"></el-option>
                <el-option label="创业训练项目" value="2"></el-option>
                <el-option label="创业实践项目" value="3"></el-option>
            </el-select>
            <span>选择院系</span>
            <el-select v-model="depart" placeholder="请选择院系" size="small" clearable @change="departs(depart)" @clear="clear" style="margin-right:10px">
                <el-option
                    v-for="item in departList"
                    :key="item.yuanId"
                    :label="item.yuanName"
                    :value="item.yuanId">
                </el-option>
            </el-select>
            <span style="margin-left:15px">选择批次</span>
            <el-select v-model="value" placeholder="请选择批次" size="small" @change="yearChange(value)" style="margin-right:10px">
                <el-option
                    v-for="item in listYear"
                    :key="item.value"
                    :label="item.value + '年'"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-button type="primary" size="mini">
                <span>导入</span>
                <input id="upfile" type="file" name="upfile" class="import_input" @change="importExcel($event.target)">
            </el-button>
            <el-button :type="this.multipleSelection.length!=0?'primary':'info'" size="mini" @click="exportStu()">导出为申报格式</el-button>
        </div>
        <el-table 
            :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)" 
            ref="multipleTable"
            style="width: 100%;height:97%"
            stripe
            :row-key="getRowKeys"
            @selection-change="handleSelectionChange"
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="selection" label="选择" :reserve-selection="true" width="55"></el-table-column>
            <el-table-column prop="projectNumber" label="项目编号" width="150px">
                <template slot-scope="scope">
                    {{scope.row.projectNumber==0?'未编号':scope.row.projectNumber}}
                </template>
            </el-table-column>
            <el-table-column prop="name" label="项目名称"></el-table-column>
            <el-table-column prop="type" label="项目类型" width="120px">
                <template slot-scope="scope">
                    <span>{{scope.row.type==1?'创新训练项目':scope.row.type==2?'创业训练项目':scope.row.type==3?'创业实践项目':''}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="isYouth" label="青年红色筑梦之旅项目" width="120px">
                <template slot-scope="scope">
                    <span>{{scope.row.isYouth==0?'是':'否'}}</span>
                </template>
            </el-table-column> 
            <el-table-column prop="priname" label="项目负责人" width="120px"></el-table-column>
            <el-table-column prop="monber" label="项目其他成员"></el-table-column>
            <el-table-column prop="tname" label="指导教师"></el-table-column>
            <el-table-column prop="academyName" label="院系名称"></el-table-column>
            <el-table-column prop="rank" label="级别" width="100px">
                <template slot-scope="scope">
                    <span>{{scope.row.rank==1?'校级':scope.row.rank==2?'省级':scope.row.rank==3?'国家级':''}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="tname" label="状态">
                <template slot-scope="scope">
                    <span>{{scope.row.states==0?'未审核':scope.row.states==1?'指导教师审核通过':scope.row.states==2?'院系审核通过':scope.row.states==3?'学校审核通过':scope.row.states==7?'待评审':scope.row.states==8?'评审中':scope.row.states==9?'评审完成待申报':scope.row.states==11?'已申报':scope.row.states==12?'待中期检查':scope.row.states==13?'中期检查通过':scope.row.states==14?'中期检查未通过':scope.row.states==15?'待结项':scope.row.states==16?'结项通过':scope.row.states==17?'结项驳回':scope.row.states==20?'已过期':''}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100px">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" size="mini">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-sizes="[10,50,100,150,200]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableData.length">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <div class="cation_Popup">
            <el-dialog
                title="项目汇总"
                :visible.sync="dialogVisible"
                width="1000px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei()"></i>
                <el-table 
                    :data="fundList" 
                    style="width: 100%"
                    stripe
                    :summary-method="getSummaries"
                    show-summary
                    :cell-style="{'text-align':'center'}"
                    :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
                >   
                    <el-table-column prop="name" label="项目类型"></el-table-column>
                    <el-table-column label="立项项目总数">
                        <template slot-scope="scope">
                            <span>{{scope.row.name=="创新训练"?scope.row.type1Is:scope.row.name=="创业训练"?scope.row.type2Is:scope.row.name=="创业实践"?scope.row.type3Is:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="参与学生总数">
                        <template slot-scope="scope">
                            <span>{{scope.row.name=="创新训练"?scope.row.type1Us:scope.row.name=="创业训练"?scope.row.type2Us:scope.row.name=="创业实践"?scope.row.type3Us:''}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" size="small" @click="addmine()">确定导出</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            pageSize:10,
            value:'',
            totalnum:0,
            currentPage:1,
            multipleSelection:[],
            tableData:[],
            dialogVisible:false,
            fundList:[],
            departList:[],
            depart:'',
            p_type:'',
        }
    },
    methods: {
        getapp(){
            this.axios.declare({
                params:{
                    schoolId:this.$store.state.schId,
                    academyId:this.depart,
                    type:this.p_type==''?0:this.p_type,
                    createTime:this.value
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.tableData = res.data.rows
                }else{
                    this.tableData = []
                }
            }).catch(err=>{

            })
        },
        // 查询学院
        init(){
            this.axios.studentuid({
                params:{
                    uid:this.$store.state.uid
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.departList = res.data.data.yuanxi
                }else{
                    this.departList = []
                }
            }).catch(err=>{

            })
        },
        // 类型筛选
        detypes(){
            this.getapp()
        },
         // 院系筛选
        departs(){
            this.getapp()
        },
        clears(){
            this.p_type = ''
            this.getapp()
        },
        //清除筛选
        clear(){
            this.depart = ''
            this.getapp()
        },
        // 导入
        importExcel(){

        },
        //导出
        exportStu(){
            if(this.multipleSelection.length!=0){
                var arr = []
                this.multipleSelection.map(item=>{
                    arr.push(item.itmId)
                })
                this.axios.statisDeclare({
                    params:{
                        itmIds:arr.join(',')
                    }
                }).then(res=>{
                    if(res.data.code==200){
                        let limit = [{name:'创新训练'},{name:'创业训练'},{name:'创业实践'}]
                        let array = []
                        limit.map(item=>{
                            if(item.name=='创新训练'){
                                array.push(Object.assign({},{name:item.name,type1Is:res.data.data.type1Is,type1Us:res.data.data.type1Us}))
                            }else if(item.name=='创业训练'){
                                array.push(Object.assign({},{name:item.name,type2Is:res.data.data.type2Is,type2Us:res.data.data.type2Us}))
                            }else if(item.name=='创业实践'){
                                array.push(Object.assign({},{name:item.name,type3Is:res.data.data.type3Is,type3Us:res.data.data.type3Us}))
                            }
                        })
                        this.fundList = array
                        this.dialogVisible = true
                    }else{

                    }
                }).catch(err=>{

                })
            }else{
                this.$message.error('请选择要申报的项目')
            }
        },
        addmine(){
            var arr = []
                this.multipleSelection.map(item=>{
                    arr.push(item.itmId)
                })
                this.axios.exportDeclare({
                    params:{
                        itmIds:arr.join(',')
                    }
                }).then(res=>{
                    if(res.data.code==200){
                        this.dialogVisible = false
                        window.open(res.data.msg, "_blank");
                    }else{

                    }
                }).catch(err=>{

                })
        },
        getRowKeys(row) {
            return row.itmId
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }else if(index === 1){
                    sums[index] = data[0].type1Is + data[1].type2Is + data[2].type3Is;
                }else if(index === 2){
                    sums[index] = data[0].type1Us + data[1].type2Us + data[2].type3Us;;
                }
            });

            return sums;
        },
        //选择批次
        yearChange(val){
            this.value = val
            this.getapp()
        },
        handleClick(rows){
            this.$router.push({name:'Projectdetails',query:{itemId:rows.itmId,val:3}})
        },
        handleClose(done){

        },
         // 全选按钮
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSizeChange(val){
            this.pageSize = val;
        },
        handleCurrentChange(val){
            this.currentPage = val;
        },
        //关闭弹窗
        closei(){
            this.dialogVisible = false
        },
        initSelectYear() {
            //初始化"检查年份"下拉框
            let year = new Date().getFullYear()
            this.value = year
            this.listYear = [];
            for(let i=0;i<5;i++){
                this.listYear.push({value:(year - i),label:(year - i)+ "年"});
            }
        },
    },
    mounted() {
        this.getapp()
        this.init()
    },
    created () {
        this.initSelectYear();
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Alistofitems.css';
</style>
<style>
.cation_Popup .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
}
.cation_Popup .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.cation_Popup .el-dialog__footer{
    text-align: center;
}
.cation_Popup .el-form-item__content{
    width: 350px;
}
.cation_Popup .el-dialog__body{
    padding: 30px 50px;
}
.cation_Popup .el-textarea__inner{
    resize: none;
    width: 450px;
}
.Projectcation.Projectcations .el-table__body-wrapper{
    min-height: calc(100vh - 357px);
}
.Projectcation.Projectcations .el-table__empty-block{
    min-height: calc(100vh - 357px);
}
.Projectcation.Projectcations .cation_Popup .el-table__body-wrapper{
    min-height: auto;
}
.Projectcation.Projectcations .cation_Popup .el-table__empty-block{
    min-height: auto;
}
.cation_Popup .el-table td{
    text-align: center;
}
</style>